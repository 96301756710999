<template>
  <b-sidebar
    id="add-new-user-sidebar"
    ref="sideBar"
    :visible="isActive"
    backdrop
    no-header
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Cadastro de lista"
    right
    shadow
    sidebar-class="sidebar-lg"
    header-class="content-sidebar-header"
    footer-class="content-sidebar-footer"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #footer="{hide}">
      <div class="p-1">
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >

            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              class="mr-2"
              @click="onSubmit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              type="button"
              @click="onCancel"
            >

              <font-awesome-icon
                prefix="far"
                :icon="['far', 'times-circle']"
                class="mr-50"
              />
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h3 class="mb-0">
          Nova lista
        </h3>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          ref="formData"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="onCancel"
        >
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome da Lista"
                placeholder="informe o nome da lista"
                name="name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <i2-form-input-file
                v-model="model.file"
                accept="text/csv"
                label="Arquivo com inelegíveis"
                placeholder="arquivo csv com os participantes inelegíveis"
                name="file"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, email, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'

export default {
  name: 'InelegibleForm',
  components: {
    I2FormInputFile,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    list: {
      type: String,
      required: false,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      model: {
        name: '',
        uuid: null,
        promotion_id: null,
        file: null,
      },
      currentPromotion: null,
      originalData: {},
      blankData: {
        name: '',
        uuid: null,
        promotion_id: null,
        file: null,
      },
    }
  },

  watch: {
    list(value) {
      if (value === '') {
        return
      }
      this.getList(value)
    },
  },
  async mounted() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push('change-promotion.index')
      return
    }
    this.currentPromotion = promotion
  },

  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const formData = new FormData()
        formData.append('name', this.model.name)
        formData.append('promotion_uuid', this.currentPromotion.uuid)
        formData.append('file', this.model.file)
        let response = {}
        if (this.model.uuid !== null) {
          formData.append('_method', 'PUT')
          response = await this.$http.post(`ineligible-lists/${this.model.uuid}`, formData)
        } else {
          response = await this.$http.post('ineligible-lists', formData)
        }
        if (response.error) {
          this.$notify.error(response.error_message)
          return
        }
        this.$notify.success('Lista salva com sucesso.')
        this.$refs.refFormObserver.reset()
        this.model = {
          uuid: null,
        }
        this.$emit('onSave', true)
      })
    },
    onCancel() {
      this.model = {
        uuid: null,
      }
      this.$refs.refFormObserver.reset()
      this.$refs.sideBar.hide()
      this.$emit('onCancel', true)
    },
    async getList(uuid) {
      const response = await this.$http.get(`ineligible-lists/${uuid}`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.model = response
    },
  },
}
</script>

<style scoped>

</style>
