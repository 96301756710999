<template>
  <b-modal
    id="participants"
    ref="participants"
    cancel-variant="outline-secondary"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered
    :ok-only="true"
    ok-title="Fechar"
    cancel-title="Fechar"
    size="lg"
    style="height: 80%"
    :title="title"
    @ok="onCancel"
    @close="onCancel"
  >
    <b-card>
      <h4>Adicionar Novo</h4>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          ref="formData"
          class=""
          @submit.prevent="handleSubmit(onSave)"
          @reset.prevent="onCancel"
        >
          <b-row>
            <b-col
              sm="4"
            >
              <i2-form-input
                v-model="model.document"
                mask="###.###.###-##"
                rules="required|cpf"
                label="Cpf do Participante"
                placeholder="Cpf do Participante"
                name="name"
                @blur="onChangeDocument"
              />
            </b-col>
            <b-col
              sm="4"
            >
              <i2-form-input
                v-model="model.name"
                rules="required"
                label="Nome do Participante"
                placeholder="Nome do Participante"
                name="name"
              />
            </b-col>
            <b-col
              sm="4"
            >
              <b-form-group
                label=""
              >
                <br>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="mr-2"
                >

                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  Salvar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <Grid
      :fields="fields"
      :items="items"
      label="Participantes"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getParticipants"
    >
      <template v-slot:search>
        <b-row class="p-0">
          <b-col
            md="10"
            class="p-0 pr-1"
          >
            <b-form-input
              v-model="search.search"
              class="filter-input"
              placeholder="Pesquisar por Nome"
              name="nome"
            />
          </b-col>
          <b-col
            sm="2"
            class="p-0 pr-1"
          >

            <b-button
              variant="secondary"
              type="button"
              @click="getParticipants(1)"
            >
              <FeatherIcon icon="SearchIcon"/>
              <span
                class="text-nowrap"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #cell(document)="data">

        {{ data.item.document | DocumentPersonBr }}
      </template>
      <template
        #cell(action)="data"
        style="width: 20%"
      >

        <i2-icon-button
          fa-icon="fa-trash"
          variant="outline-danger"
          class="mr-1"
          @click="onDelete(data.item)"
        />

      </template>
    </Grid>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { required, cpf } from '@validations'
import { ValidationObserver } from 'vee-validate'
import { isValid as isValidCpf } from '@fnando/cpf'

export default {
  name: 'IneligibleParticipants',
  components: {
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    list: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      required,
      cpf,
      search: {
        search: '',
      },
      model: {
        name: '',
        document: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-50',
        },
        {
          key: 'document',
          label: 'Documento',
          class: 'w-30',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentList: '',
      items: [],
      currentPromotion: {},
      isActivated: false,
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.$refs.participants.show()
        this.getParticipants()
      } else {
        this.$refs.participants.hide()
      }
    },
  },
  async mounted() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push('change-promotion.index')
      return
    }
    this.currentPromotion = promotion
  },
  methods: {
    async getParticipants(page) {
      this.items = await this.$search.get(
        `promotions/${this.currentPromotion.uuid}/ineligible-lists/${this.list}/participants`,
        page,
        this.search,
        '',
      )
    },
    onCancel() {
      this.$emit('onClose', true)
    },
    onDelete(item) {
      const data = { participants: [item.document] }
      const route = `promotions/${this.currentPromotion.uuid}/ineligible-lists/${this.list}/participants`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do participante da lista',
        'Excluir participante',
        () => {
          this.$notify.success(`participante ${item.name} removido da lista com sucesso.`)
          this.getParticipants()
          this.$emit('onDelete')
        },
        data,
      )
    },
    onSave() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const data = { participants: [this.model] }
        const route = `promotions/${this.currentPromotion.uuid}/ineligible-lists/${this.list}/participants`
        const response = await this.$http.post(route, data)
        if (response.error) {
          this.$notify.error(response.error_message)
          return
        }
        this.$notify.success('Participante adicionado a lista com sucesso.')
        this.$refs.refFormObserver.reset()
        this.model = {
          name: '',
          document: '',
        }
        this.getParticipants()
        this.$emit('onSave')
      })
    },
    async onChangeDocument() {
      if (this.model.document !== '' && isValidCpf(this.model.document)) {
        const document = this.model.document.match(/\d+/g).join('')
        const response = await this.$http.get(`participants?search=search:${document}`)
        if (response.length > 0) {
          this.model.name = response[0].name
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
